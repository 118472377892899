<script setup lang="ts"></script>

<template>
  <header id="header" role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container">
        <div class="fr-header__body-row">
          <div class="fr-header__brand">
            <div class="fr-header__brand-top">
              <div class="fr-header__logo">
                <p class="fr-logo">
                  République
                  <br />Française
                </p>
              </div>
            </div>
            <div class="fr-header__service">
              <p class="fr-header__service-title">
                Ara
                <span
                  class="fr-badge fr-badge--sm fr-badge--info fr-badge--no-icon"
                  >BÊTA</span
                >
              </p>
              <p class="fr-header__service-tagline">
                Réalisez vos audits d’accessibilité numérique
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.user-dropdown {
  text-align: initial !important;
}
</style>
